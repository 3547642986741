import { Line, getElementAtEvent } from 'react-chartjs-2';
import _ from 'lodash';
import moment from 'moment';
import './ExpandedNightReport.css'
import WindDirectionChart from './WindDirectionChart';
import { useEffect, useRef } from 'react';
import $ from 'jquery';
import Slider from '@mui/material/Slider'

export default function ExpandedNightReport({ report, latitude, longitude }) {
    var planetarium;
    useEffect(() => {
        planetarium = document.virtualsky({
            id: 'starmap',
            projection: 'stereo',
            latitude: latitude,
            longitude: longitude,
            showstarlabels: true,
            gridlines_az: true,
            az: 0,
            constellations: true,
            objects: getExtraProjectionObjects(report),
            clock: new Date(report.sunsetTime),
            magnitude: localStorage.getItem('maxMagnitude') || 12
        });
        // setInterval(() => {
        //     planetarium.setClock(new Date()).calendarUpdate();
        // }, 1000)

    })

    const altitudeChartRef = useRef();
    if (!report) return (<div className="expandedNightReport"></div>)
    
    return (
        <div className="expandedNightReport">
            <div className="dataGraph">
                <Line 
                    ref={altitudeChartRef}
                    id="positionData" 
                    data={mapReportAltitudesForData(report)} 
                    options={getAltitudeChartOptions(getSunsetValue(report), getSunriseValue(report))}
                    onClick={(event) => {
                        const element = getElementAtEvent(altitudeChartRef.current, event)[0];
                        const reportData = mapReportAltitudesForData(report);
                        const line = reportData.datasets[element.datasetIndex];
                        const bodyReport = report.bodyVisibilityReport[element.datasetIndex];
                        planetarium.setClock(new Date(bodyReport.altitudes[element.index].time)).calendarUpdate();
                        planetarium.az_off = -180;
                        planetarium.changeAzimuth(bodyReport.azimuths[element.index].azimuth)
                        
                    }}>
                </Line>

            </div>
            <div className="dataGraph">

                <Line id="weatherData" data={mapReportWeatherForData(report)} options={getWeatherChartOptions()}></Line>
            </div>
            <div className="dataGraph">
                <WindDirectionChart data={report}></WindDirectionChart>

            </div>
            <div className="starmap" id="starmap">
            <Slider
                        className="settingsSlider"
                        getAriaLabel={() => 'Max Magnitude'}
                        value={0}
                        onChange={(a, newValue) => this.handleMagChange(a, newValue)}
                        valueLabelDisplay="on"
                        // valueLabelFormat={this.valuetext}
                        // getAriaValueText={this.valuetext}
                        min={-15}
                        max={30}
                    />
            </div>
            
        </div>
    );
}
function getExtraProjectionObjects(report) {
    return report.bodyVisibilityReport.filter(body => !!body.ra && !!body.dec).map(body => ({
        ra: body.ra,
        dec: body.dec,
        name: body.bodyName
    }))
}
function mapReportAltitudesForData(report) {
    const labels = getAltitudeLabels(report)
    const data = {
        title: 'Body Altitudes',
        labels: labels,
        
        // report.weatherReport.skyCover.map(v => v.time),
        datasets:
            report.bodyVisibilityReport.map((body, index) => {
                return {
                    id: index,
                    label: body.bodyName,
                    borderColor: getBodyColor(body.bodyName, body.type),
                    backgroundColor: 'black',
                    data: labels.map(label => {
                        return _.get(_.find(body.altitudes, (altitude) => moment(altitude.time).format('HH:00') === label), 'altitude', 0)
                    })
                }
            }),
    }
    return data;
}
function getAltitudeLabels(report) {
    return _.uniq(_.flatten(report.bodyVisibilityReport.map(
                body => body.altitudes.map(a => a.time)
            ))).sort(
                (a, b) => moment(a).valueOf() - moment(b).valueOf()
            ).map(
                a => moment(a).format('HH:00')
            );
}
function getSunsetValue(report) {
    const sunset = moment(report.sunsetTime);
    const timestamps = _.uniq(_.flatten(report.bodyVisibilityReport.map(body => body.altitudes.map(a => a.time)))).sort(
        (a, b) => moment(a).valueOf() - moment(b).valueOf()
    );
    var lowerIndex = 0;
    var found = false;
    for(var i = 0; i < timestamps.length;i++){
        if(sunset.hours() < moment(timestamps[i]).hours()){
            lowerIndex = i - 1;
            found = true;
            break;
        }
    }
    if(!found) return 50;
    const minutes = sunset.minutes();
    return lowerIndex + (minutes / 60);
}
function getSunriseValue(report) {
    const sunrise = moment(report.sunriseNextDay);
    const timestamps = _.uniq(_.flatten(report.bodyVisibilityReport.map(body => body.altitudes.map(a => a.time)))).sort(
        (a, b) => moment(a).valueOf() - moment(b).valueOf()
    );
    var lowerIndex = 0;
    var found = false;
    for(var i = 0; i < timestamps.length;i++){

        if(moment(timestamps[i]).hours() > 12) {
            
            continue;
        
        }
        
        if(sunrise.hours() < moment(timestamps[i]).hours()){
            lowerIndex = i - 1;
            found = true;
            break;
        }
        
        
    }
    if(!found) return 50;
    const minutes = sunrise.minutes();
    return lowerIndex + (minutes / 60);
}
function getAltitudeChartOptions(sunsetValue, sunriseValue) {
    return {

        cubicInterpolationMode: 'monotone',
        scales: {
            y: {
                display: true,
                min: 0,
                max: 90
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
            mode: 'point'
        },
        plugins: {
            title: {
                text: 'Altitude',
                display: true
            },
            annotation: {
                annotations: {
                    sunsetLine: {
                        type: 'line',
                        mode: 'vertical',
                        borderColor: 'red',
                        xMin: sunsetValue,
                        xMax: sunsetValue,
                        // xScaleId: 'x-axis-0'
                    },
                    sunriseLine: {
                        type: 'line',
                        mode: 'vertical',
                        borderColor: 'red',
                        xMin: sunriseValue,
                        xMax: sunriseValue,
                        // xScaleId: 'x-axis-0'
                    },

                }
            }
        }

    }
}
function getWeatherChartOptions() {
    return {

        cubicInterpolationMode: 'monotone',
        scales: {
            y: {
                display: true,
                min: 0,
                max: 100,
                grid: {
                    color: '#DDDDDD44'
                }
            }
        },
        maintainAspectRatio: false,
        responsive: true,

        plugins: {
            title: {
                text: 'Weather',
                display: true
            }
        }
    }
}
function mapReportWeatherForData(report) {
    const labels = report.weatherReport.skyCover.map(s => moment(s.time).format('HH:00'));
    const data = {
        labels: labels,
        // report.weatherReport.skyCover.map(v => v.time),
        datasets: [{
            id: 0,
            label: 'Cloud Cover (%)',
            data: report.weatherReport.skyCover.map(s => s.value),
            borderColor: '#E5E5E5',
            backgroundColor: '#FFFFFF'
        },
        {
            id: 1,
            label: 'Relative Humidity (%)',
            data: report.weatherReport.relativeHumidity.map(s => s.value),
            borderColor: '#227C9D',
            backgroundColor: '#47B0D7'
        }, {
            id: 2,
            label: 'Temperature (F)',
            data: report.weatherReport.temperature.map(s => s.value * 9 / 5 + 32),
            borderColor: '#D84727',
            backgroundColor: "#DF6C53"
        }, {
            id: 3,
            label: 'Wind Speed (mph)',
            data: report.weatherReport.windSpeed.map(s => s.value * 0.62137),
            borderColor: '#B37BA4',
            backgroundColor: '#E2E9D8'
        }]


    }
    return data;
}
function getBodyColor(bodyname, type){
    if(type === 'Comet') {
        return '#42ad5f'
    }
    switch(bodyname) {
        case 'Mercury': return '#f59985';
        case 'Venus': return '#f5e885';
        case 'Moon': return '#b3b3b3';
        case 'Earth': return '#2d8b41';
        case 'Mars': return '#ba560b';
        case 'Jupiter': return '#d6d781'
        case 'Saturn': return '#dfdfad'
        case 'Uranus': return '#5ebcb9'
        case 'Neptune': return '#1d7092'
        case 'Pluto': return '#929379'
        default: return '#7a47bd'
    }
}