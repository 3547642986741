import { useEffect } from 'react';
import './NightReport.css'

function NightReport({ report, onClick, showNavButtons, prevAction, nextAction, collapse }) {
    return (
        <div className="nightReport" onClick={onClick}>
            {/* Light Pollution: {report.lightPollutionReport.levelExplain} */}
            <h1 className="dateDisplay">{getSimpleDate(new Date(report.sunsetTime))}</h1>
            {
                showNavButtons ?
                    <div className="navButtons">
                        <div onClick={prevAction} className="navButton">&lt;</div>
                        <div onClick={nextAction} className="navButton">&gt;</div>
                    </div>
                    : null
            }
            {
                showNavButtons ? 
                <div className='collapseButton' onClick={collapse}>x</div>
                : null
            }
            

            <div className="dateName">{getDayName(new Date(report.sunsetTime))}</div>
            <div className="sunsetTime">
                <img className="visiblePlanetInfoIcon" src="/icons/icons8-sunset-30.png"></img>

                {getSimpleTime(new Date(report.sunsetTime))}
            </div>
            <div className="cloudCoverDisplay">
                <img className="visiblePlanetInfoIcon" src="/icons/icons8-cloud-30.png"></img>

                {getAverageCloudCover(report).toFixed(2)}%
            </div>
            <div className="temperatureDisplay">
                <span className="minTempDisplay">{getMinTemperature(report).toFixed(0)}°</span>
                <img className="visiblePlanetInfoIcon" src="/icons/icons8-thermometer-30.png"></img>

                <span className="maxTempDisplay">{getMaxTemperature(report).toFixed(0)}°</span>

            </div>
            <div className="mobileMoonPhaseDisplay">
                {
                    getMobileMoonPhaseDisplay(report)
                }
            </div>
            <div className="visiblePlanetsDisplay">
                {report.bodyVisibilityReport.map(body => {
                    return <div key={body.bodyName} className="visiblePlanetEntry">
                        <img className="visiblePlanetIcon" src={getIconFile(body.bodyName, body.customImage)}></img>
                        <div className="visiblePlanetInfo">
                            <h3 className={isSuperMoon(body) ? "visiblePlanetNameTag superMoonTag" : "visiblePlanetNameTag"}>
                                {body.bodyName}
                                {body.phase ? <img className="moonPhaseIcon" src={getMoonPhaseIcon(body.phase)}></img> : null}
                                
                            </h3>
                            <div className="visiblePlanetMagnitude">
                                <img className="visiblePlanetInfoIcon" src="/icons/brightness.png"></img>
                                {body.minMagnitude.toFixed(2)}
                            </div>
                            <div className="visiblePlanetAltitude">
                                <img className="visiblePlanetInfoIcon" src="/icons/inclination.png"></img>
                                {body.maxAltitude.toFixed(2)}°
                            </div>
                            <div className="visiblePlanetTimeframe">
                                <div>
                                    {/* <img className="visiblePlanetInfoIcon" src="/icons/icons8-bodyrise-30.png"></img> */}
                                    Rise: {getSimpleTime(new Date(body.visibleFrom))}
                                </div>
                                <div>
                                    {/* <img className="visiblePlanetInfoIcon" src="/icons/icons8-bodyset-30.png"></img> */}
                                    Set: {getSimpleTime(new Date(body.visibleTo))}
                                </div>
                            </div>
                        </div>

                    </div>
                })}
            </div>
            {/* 
      <div>
        {report.visibleBodiesByHour.map(hour => {
            return <div>
                {new Date(hour.timestamp).toLocaleTimeString()} ({getCloudCoverForHour(report, hour.timestamp)}%) ({getTemperatureForHour(report, hour.timestamp)}°F)
                <br></br>
                {hour.visibleBodies.map(body => `${body.name} (${body.positions[0].magnitude})`).join(',')}
                </div>
        })}
      </div>
      Sunrise: {new Date(report.sunriseNextDay).toLocaleTimeString()}
       */}
        </div>
    );
}
function getMobileMoonPhaseDisplay(report){
    const moonReport = report?.bodyVisibilityReport.find((body) => body.bodyName === 'Moon');
    if(!moonReport) return null;
    return <img className="moonPhaseIcon" src={getMoonPhaseIcon(moonReport.phase)}></img>
}
function getMoonPhaseIcon(phaseName) {
    return `/icons/moon-phases/${phaseName.toLowerCase().replace(' ', '-')}.png`
}
function isSuperMoon(body){
    return body.bodyName === 'Moon' && body.distanceFromEarth > 368630 && body.phase === 'New moon';
}
function getCloudCoverForHour(report, time) {
    return (report.weatherReport.skyCover.find(item => new Date(item.time).getTime() === new Date(time).getTime()) || { value: '-' }).value;
}
function getTemperatureForHour(report, time) {
    return (report.weatherReport.temperature.find(item => new Date(item.time).getTime() === new Date(time).getTime()) || { value: -1000 }).value * (9 / 5) + 32;
}
function getAverageCloudCover(report) {
    return report.weatherReport.skyCover.reduce((prev, cur, index, array) => prev + cur.value, 0) / report.weatherReport.skyCover.length;
}
function getMinTemperature(report) {
    return Math.min(...report.weatherReport.temperature.map(t => t.value)) * 9 / 5 + 32;
}
function getMaxTemperature(report) {
    return Math.max(...report.weatherReport.temperature.map(t => t.value)) * 9 / 5 + 32;

}
function getIconFile(bodyName, customImage) {
    if(customImage) return customImage;
    return `/icons/icons8-${bodyName.toLowerCase()}-96.png`;
}
function getSimpleDate(date) {
    return `${date.getMonth() + 1}/${date.getDate()}`
}
function getDayName(date) {
    var days = ['Sun.', 'Mon.', 'Tues.', 'Wed.', 'Thurs.', 'Fri.', 'Sat'];
    // var d = new Date(dateString);
    var dayName = days[date.getDay()];
    return dayName
}
function getSimpleTime(date) {
    return `${date.getHours() % 12}:${date.getMinutes().toString().padStart(2, '0')} ${date.getHours() >= 12 ? 'PM' : 'AM'}`
}

export default NightReport;