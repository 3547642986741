import _ from 'lodash';
import moment from 'moment';
import './WindDirectionChart.css'
import React from 'react';

export default class WindDirectionChart extends React.Component {

    componentDidMount() {

        this.drawGraph();


    }
    dissectData(dataPoints) {
        return {
            min: Math.min(...dataPoints) * Math.PI / 180,
            max: Math.max(...dataPoints) * Math.PI / 180,
            avg: dataPoints.reduce((prev, cur, index, array) => cur + prev, 0) / dataPoints.length * Math.PI / 180
        }
    }
    async drawGraph() {
        const data = this.props.data.weatherReport.windDirection.map(s => s.value / 360 * 100)
        const dataProcessed = this.dissectData(data);
        await this.waitForElm('#windDirectionCanvas');
        const element = document.getElementById('windDirectionCanvas')

        const parent = element.parentElement;
        const parentWidth = parent.offsetWidth;
        const parentHeight = parent.offsetHeight;

        element.style.width = parentWidth + 'px';
        element.style.height = parentHeight + 'px';



        const dpi = window.devicePixelRatio || 1;
        element.width = parentWidth * dpi;
        element.height = parentHeight * dpi;
        
        const {width, height} = element;
        const ctx = element.getContext("2d");
        ctx.fillStyle = "#243E36"
        const radius = height / 2 * .8
        const centerX = width / 2;
        const centerY = height / 2;
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, Math.PI * 2, true);
        ctx.fill();
        ctx.closePath()

        ctx.fillStyle = '#7CA982'
        ctx.moveTo(centerX, centerY);
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, -dataProcessed.min, -dataProcessed.max, true);
        ctx.lineTo(centerX, centerY);
        ctx.fill();
        ctx.closePath()
        ctx.beginPath()
        // ctx.fillStyle = 'white';
        ctx.lineWidth = dpi;
        ctx.strokeStyle = 'white';
        ctx.moveTo(centerX, centerY);
        ctx.lineTo(
            centerX + Math.cos(-dataProcessed.avg) * radius, 
            centerY + Math.sin(-dataProcessed.avg) * radius
            );
        ctx.stroke();

        
    }
    waitForElm(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }
    
            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    resolve(document.querySelector(selector));
                    observer.disconnect();
                }
            });
    
            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }
    render(){
        this.drawGraph();
        return (<div className='container-wd'>
            <div className='title'>Wind Direction</div>
            <div className='container-wd'>
                <canvas id='windDirectionCanvas'>

                </canvas>
            </div>
            
        </div>)
    }
}

