import logo from './logo.svg';
import './App.css';
import NightReport from './components/NightReport';
import ExpandedNightReport from './components/ExpandedNightReport';
import superagent from 'superagent';
import React from 'react';
import { RevolvingDot } from 'react-loader-spinner';
import { Chart as ChartJS, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import IconButton from '@mui/material/IconButton'
import SettingsDialog from './components/SettingsDialog';

const truittsLanding = {
  lat: 38.099422,
  long: -75.338075
}
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nights: [],
      loading: true,
      erroredOut: false,
      selectedIndex: 100,
      selectedDate: '',
      latitude: 0,
      longitude: 0,
      settingsOpen: false,
    }

  }
  componentDidMount() {
    const queryString = window.location.search.substring(1);
    const query = queryString.split('&').reduce((prev, curr, i, arr) => {
      let obj = {};
      obj[curr.split('=')[0]] = curr.split('=')[1];
      return Object.assign(prev, obj);
    }, {});
    query.longitude = parseFloat(query.longitude);
    query.latitude = parseFloat(query.latitude);

    if (query.date && query.date.length > 0 && query.date.indexOf('-') > 0) {
      this.setState({
        selectedDate: query.date
      })
    }
    if (query.longitude && query.latitude) {
      this.setState({
        longitude: query.longitude,
        latitude: query.latitude,
      }, () => {
        this.getReport({
          lat: query.latitude,
          long: query.longitude
        });
      })

    } else {
      //check to see if we have lat and long in localstorage?
      var defaultLocation = { value: truittsLanding};
      try {
        defaultLocation = JSON.parse(localStorage.getItem('selectedLocation')) || defaultLocation;
      } catch(error) {}


      this.setState({
        longitude: defaultLocation.value.long,
        latitude: defaultLocation.value.lat,
      }, () => {
        this.getReport(defaultLocation.value);

      })
    }

    ChartJS.register(...registerables, annotationPlugin);

  }
  constructQueryString(parameters) {
    const keys = Object.keys(parameters)
    return `?${keys.map(key => `${key}=${parameters[key]}`).join('&')}`;
  }
  render() {
    return (
      <div className="App">
        <div className="weekCalendarDisplay">
          {
            (this.state.selectedIndex === 100) ?

              this.state.nights.map((night, i) => <NightReport report={night} key={'nightReport' + i} onClick={() => this.selectReport(i)} />)
              :
              <NightReport
                report={this.state.nights[this.state.selectedIndex]}
                // onClick={() => this.selectReport(100)} 
                showNavButtons={true}
                prevAction={() => this.selectReport(Math.max(0, this.state.selectedIndex - 1))}
                nextAction={() => this.selectReport(Math.min(this.state.nights.length - 1, this.state.selectedIndex + 1))}
                collapse={() => this.collapseReport()}
              />
          }
          {
            (this.state.selectedIndex !== 100) ?

              <ExpandedNightReport 
                report={this.state.nights[this.state.selectedIndex]} 
                latitude={this.state.latitude} 
                longitude={this.state.longitude}>
              </ExpandedNightReport> :
              null

          }
          <RevolvingDot
            height="10vh"
            width="10vh"
            radius="3vh"
            color="#FFFFFF"
            secondaryColor=''
            ariaLabel="revolving-dot-loading"
            wrapperStyle={{}}
            wrapperClass="centeredElement"
            visible={this.state.loading}
          />
          {this.state.erroredOut ? <div className="errorMessage centeredElement">Unable to fetch astronomy report.</div> : null}
        </div>
        <div className='configurator'>
          <a href="https://git.edwardpeterson.dev/cubemaster21/nightlight">View Source</a>
          {/* Truitt's Landing */}
          
          <IconButton aria-label="settings" onClick={() => this.openSettingsModal()}>
            <img src="/icons/icons8-settings-30.png"></img>
          </IconButton>
          <SettingsDialog
            // selectedValue={selectedValue}
            open={this.state.settingsOpen}
            onClose={(a) => this.onSettingsClosed(a)}
          />
        </div>

      </div>
    );
  }
  openSettingsModal() {
    this.setState({ settingsOpen: true });
  }
  onSettingsClosed(settings) {
    this.setState({ settingsOpen: false });
    if (settings) {
      this.setState({
        nights: [],
        loading: true,
        erroredOut: false,
        selectedIndex: 100,
        selectedDate: '',
        latitude: settings.selectedLocation.value.lat,
        longitude: settings.selectedLocation.value.long,
      })
      this.getReport({ lat: settings.selectedLocation.value.lat, long: settings.selectedLocation.value.long })
    }

  }
  collapseReport() {
    // window.history.pushState({}, null, `${window.location.origin}`);
    this.selectReport(null);

    this.setState({
      selectedIndex: 100
    })
  }
  selectReport(index) {


    let url = window.location.origin + `/?longitude=${this.state.longitude}&latitude=${this.state.latitude}`;
    if (index !== null) {
      url += `&date=${new Date(this.state.nights[index].sunsetTime).getMonth() + 1}-${new Date(this.state.nights[index].sunsetTime).getDate()}`
      this.setState({
        selectedIndex: index
      })
    }
    //update the url for linking ability
    window.history.pushState({}, null, url)

  }
  async getReport(location) {
    const url = process.env.NODE_ENV === 'production' ?
      '/api/getMultiReport' :
      'http://localhost:4500/getMultiReport';


    var objectsFromStorage = localStorage.getItem('ngcObjects');
    try {
      objectsFromStorage = JSON.parse(objectsFromStorage) || [];
    } catch (error) {
      //couldn't parse. Just supply an empty array.
      objectsFromStorage = [];
    }

    superagent.get(url)
      .set('Accept', 'application/json')
      .query({
        lat: location.lat,
        long: location.long,
        timeWindow: localStorage.getItem('timeWindow') || '0,15',
        maxMagnitude: localStorage.getItem('maxMagnitude') || 12,
        ngcObjects: objectsFromStorage.map(object => object.name).join(',')
        // ngcObjects: 'NGC1976'
      })
      .then(response => {
        this.selectReport(null);
        this.setState({
          nights: response.body,
          loading: false
        })
        if (this.state.selectedDate.toString().length > 0) {
          const dateSplit = this.state.selectedDate.split('-');
          const month = dateSplit[0];
          const day = dateSplit[1];
          let found = false;
          for (var i = 0; i < response.body.length; i++) {
            let date = new Date(response.body[i].sunsetTime)
            if ((date.getMonth() + 1) == month && date.getDate() == day) {
              this.setState({
                selectedIndex: i
              })
              found = true;
              break;
            }
          }
          if (!found) {
            //remove it from the url
            this.selectReport(null);
            // window.history.pushState({}, null, `${window.location.origin}`)

          }

        }



      }).catch(error => {
        console.error(error);
        this.setState({
          loading: false,
          erroredOut: true
        })
      })
  }
}





export default App;
