import Dialog from '@mui/material/Dialog'
import Slider from '@mui/material/Slider'
import DialogTitle from '@mui/material/DialogTitle'
import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import './SettingsDialog.css';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { Autocomplete, TextField, DeleteIcon, IconButton } from '@mui/material'
import superagent from 'superagent';
import _ from 'lodash'
import Select from 'react-select';


const spruceKnob = {
    lat: 38.7160523,
    long: -79.5374591
  }
  const truittsLanding = {
    lat: 38.099422,
    long: -75.338075
  }
  const hebron = {
    lat: 38.4062354,
    long: -75.6546748
  }
  const locations = [
    { value: truittsLanding, label: "Truitt's Landing", lookupName: 'truittsLanding' },
    { value: hebron, label: 'Hebron', lookupName: 'hebron' },
    { value: spruceKnob, label: 'Spruce Knob', lookupName: 'spruceKnob' }
  ];
export default class SettingsDialog extends React.Component {
    constructor(props) {
        super(props);

        


        this.state = this.getInitOptions();
    }
    getInitOptions() {
        var objectsFromStorage = [];
        try {
            objectsFromStorage = JSON.parse(localStorage.getItem('ngcObjects')) || [];
        } catch (error) {}

        var defaultLocation = locations[0].value;
        try {
            defaultLocation = JSON.parse(localStorage.getItem('selectedLocation')) || locations[0];
        } catch(error) {}

        return {
            timeWindow: localStorage.getItem('timeWindow')?.split(',').map(n => parseInt(n)) || [0, 15],
            maxMagnitude: parseInt(localStorage.getItem('maxMagnitude') || 12),
            ngcObjects: objectsFromStorage,
            autocompleteResults: [],
            locations: locations,
            selectedLocation: _.find(locations, l => JSON.stringify(l) === JSON.stringify(defaultLocation))
        }
    }
    // { onClose, selectedValue, open } = props;

    handleClose() {
        this.props.onClose('done');
    }

    handleListItemClick(value) {
        this.props.onClose(value);
    }


    render() {
        return <Dialog open={this.props.open}>
            <DialogTitle>Settings</DialogTitle>
            <div className="settingsDialog">
                <div className='settingsItem'>
                    Location
                    <Select
                        className='locationSelector'
                        options={this.state.locations}
                        menuPlacement='bottom'

                        value={this.state.selectedLocation}
                        onChange={(newValue) => {
                            this.setState({
                                selectedLocation: newValue
                            })
                            // this.setState({
                            //     nights: [],
                            //     loading: true,
                            //     erroredOut: false,
                            //     selectedIndex: 100,
                            //     selectedDate: '',
                            //     latitude: newValue.value.lat,
                            //     longitude: newValue.value.long
                            // }, () => {

                            //     this.getReport(newValue.value);

                            // })
                        }}
                    ></Select>
                </div>
                <div className='settingsItem'>
                    Max Magnitude:

                    <Slider
                        className="settingsSlider"
                        getAriaLabel={() => 'Max Magnitude'}
                        value={this.state.maxMagnitude}
                        onChange={(a, newValue) => this.handleMagChange(a, newValue)}
                        valueLabelDisplay="on"
                        // valueLabelFormat={this.valuetext}
                        // getAriaValueText={this.valuetext}
                        min={-15}
                        max={30}
                    />
                </div>

                <div className='settingsItem'>
                    Time Window:

                    <Slider
                        className="settingsSlider"
                        getAriaLabel={() => 'Time Window'}
                        value={this.state.timeWindow}
                        onChange={(a, newValue) => this.handleChange(a, newValue)}
                        valueLabelDisplay="on"
                        valueLabelFormat={this.valuetext}
                        getAriaValueText={this.valuetext}
                        min={0}
                        max={15}
                    />
                </div>
                <div className="settingsItem">
                    Additional Objects
                    <List className="additionalObjectsList">
                        {
                            this.state.ngcObjects.map(object =>
                                <ListItem className="additionalObjectItem"
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="delete" onClick={() => this.removeNGCObject(object)}>
                                            <img src="/icons/icons8-delete-30.png"></img>
                                        </IconButton>
                                    }
                                    key={object.name}>
                                    {this.getAdditionalObjectDisplayString(object)}
                                </ListItem>)
                        }
                    </List>
                    <Autocomplete
                        options={this.state.autocompleteResults}
                        onInputChange={(event, input, reason) => this.getAutocompleteResults(input, reason, event)}
                        onChange={(event, value) => this.addNGCObject(value)}
                        getOptionLabel={(option) => `${_.get(option, 'commonNames.0', '')} (${option.name})`}
                        filterOptions={(x) => x} // filter out ones already in the list
                        renderInput={(params) => (
                            <TextField {...params} label="Search" variant="outlined" />
                        )}
                    />

                </div>
                <div className="actionButtons">
                    <Button className="saveSettingsButton" onClick={() => this.saveSettings()}>Save</Button>
                    <Button className="saveSettingsButton" onClick={() => this.cancelChanges()} color="error">Cancel</Button>

                </div>



            </div>
        </Dialog>
    }
    getAdditionalObjectDisplayString(object) {
        if(_.get(object, 'commonNames.0')){
            return `${_.get(object, 'commonNames.0')} (${object.name})`
        } else {
            return object.name
        }
    }

    valuetext(value) {
        //if we start the report always at 4PM, we'll just start his here too.
        let hourNumber = (value + 16) % 12;
        return `${hourNumber || 12}:00 ${Math.floor((value + 16) / 12) % 2 === 1 ? 'PM' : 'AM'}`
    }
    handleChange(event, newValue) {
        this.setState({
            timeWindow: newValue
        })
    }
    handleMagChange(event, newValue) {
        this.setState({
            maxMagnitude: newValue
        })
    }
    saveSettings() {
        localStorage.setItem('maxMagnitude', this.state.maxMagnitude);
        localStorage.setItem('timeWindow', this.state.timeWindow);
        localStorage.setItem('ngcObjects', JSON.stringify(this.state.ngcObjects));
        localStorage.setItem('selectedLocation', JSON.stringify(this.state.selectedLocation))

        this.props.onClose({
            maxMagnitude: this.state.maxMagnitude,
            timeWindow: this.state.timeWindow,
            ngcObjects: this.state.ngcObjects.map(ngcObject => ngcObject.name),
            selectedLocation: this.state.selectedLocation
        });
    }
    cancelChanges() {
        this.props.onClose(null)
        this.setState(this.getInitOptions() )

        
    }
    addNGCObject(value) {
        if (!value) return;

        this.setState({
            ngcObjects: _.uniqBy([...this.state.ngcObjects, value], (a) => a.name)
        })
    }
    removeNGCObject(object) {
        this.setState({
            ngcObjects: this.state.ngcObjects.filter(o => o.name !== object.name)
        })
    }
    getAutocompleteResults(phrase, reason, event) {
        if (reason === 'reset')
            return
        if (!phrase) {
            this.setState({
                autocompleteResults: []
            });
            return;
        }
        const url = process.env.NODE_ENV === 'production' ?
            '/api/autoCompleteNGC' :
            'http://localhost:4500/autoCompleteNGC';
        superagent.get(url).query({
            phrase
        }).then(results => {
            this.setState({
                autocompleteResults: results.body
            })
        });
    }
}